import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { addProductAsync, resetProductAddStatus, selectProductAddStatus } from '../../products/ProductSlice';
import { Button, FormControl, FormGroup, InputLabel, MenuItem, Select, Stack, TextField, Typography, useMediaQuery, useTheme, FormHelperText, FormControlLabel, Checkbox, Box } from '@mui/material';
import { Controller, useForm } from "react-hook-form";
import { selectBrands } from '../../brands/BrandSlice';
import { selectCategories } from '../../categories/CategoriesSlice';
import { toast } from 'react-toastify';
import axios from 'axios';
import { axiosi } from '../../../config/axios';
import { FixedSizeList } from 'react-window';
import productData from './Productdata.json';
import ClothingComponent from './ClothingComponent';
const availableSizes = productData.availableSizes;


export const AddProduct = () => {
    const { register, handleSubmit, control, reset, formState: { errors }, setError, clearErrors } = useForm({
        defaultValues: {
            availableSizes: []
        }
    });

    const [flag, setFlag] = useState(false)
    const [cat, setCat] = useState(null);
    const dispatch = useDispatch();
    const brands = useSelector(selectBrands);
    const categories = useSelector(selectCategories);
    const productAddStatus = useSelector(selectProductAddStatus);
    const navigate = useNavigate();
    const theme = useTheme();
    const is1100 = useMediaQuery(theme.breakpoints.down(1100));
    const is480 = useMediaQuery(theme.breakpoints.down(480));
    const [hsnData, setHsnData] = useState([]); // State to store the HSN codes
    const [selectedHsn, setSelectedHsn] = useState(''); // Selected HSN value
    const [searchTerm, setSearchTerm] = useState('');
    const [hsnerrors, setErrors] = useState({ HSN: null }); // State to handle errors
    useEffect(() => {
        if (productAddStatus === 'fullfilled') {
            setFlag(false)
            reset();
            toast.success("New product added");
            navigate("/admin/dashboard");
        } else if (productAddStatus === 'rejected') {
            setFlag(false)
            toast.error("Error adding product, please try again later");
        }
    }, [productAddStatus]);

    useEffect(() => {
        return () => {
            dispatch(resetProductAddStatus());
        };
    }, [dispatch]);



    const handleAddProduct = (data) => {
        if (selectedHsn == '') {
            toast.error("Please select an HSN code");
            return;
        }

        let newProduct = {
            SKUID: data.SKUID,
            HSN: selectedHsn,
            title: data.title,
            category: data.category,
            brand: data.brand,
            availableSizes: data.availableSizes,
            description: data.description,
            price: data.price,
            discountPercentage: data.discountPercentage,
            ShippingCharge: data.ShippingCharge,
            Weight: data.Weight,
            length: data.length,
            breath: data.breath,
            height: data.height,
            stockQuantity: data.stockQuantity,
            images: [data.image0, data.image1, data.image2, data.image3],
            thumbnail: data.thumbnail,
        };

        // console.log(data.category);
        // Add conditional fields for "clothing" category
        if (cat == "clothing") {
            newProduct.color = data.color;
            newProduct.type = data.type;
            newProduct.fabric = data.fabric;
            newProduct.pattern = data.pattern;
            newProduct.idealFor = data.idealFor;
            newProduct.topLength = data.topLength;
            newProduct.occasion = data.occasion;
            newProduct.fabricCare = data.fabricCare;
            newProduct.bottomLength = data.bottomLength;
            newProduct.dupattaLength = data.dupattaLength;
            newProduct.stitchingType = data.stitchingType;
            newProduct.fabricPurity = data.fabricPurity;
            newProduct.ornamentationType = data.ornamentationType;
            newProduct.regionalSpeciality = data.regionalSpeciality;
            newProduct.patternPrintType = data.patternPrintType;
            newProduct.trend = data.trend;
            newProduct.secondaryColor = data.secondaryColor;
        }

        // Remove unused fields from the data
        delete data.image0;
        delete data.image1;
        delete data.image2;
        delete data.image3;
        // console.log(newProduct);
        setFlag(true);
        dispatch(addProductAsync(newProduct)); // Dispatch the JSON object
    };
    useEffect(() => {
        axiosi.get('data/hsn')
            .then((response) => {
                // console.log(response.data);
                setHsnData(response.data)
                // Access the data from the response object
            })
            .catch((err) => {
                console.error(err);  // Log any errors
            });
    }, []);


    const handleHsnChange = (event) => {
        const selectedValue = event.target.value; // Get the selected value
        setSelectedHsn(selectedValue); // Update selected HSN value
        setErrors({ HSN: null }); // Clear any previous errors
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value); // Update search term
    };


    const filteredHsnData = hsnData.filter(hsn =>
        hsn.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        hsn.code.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const Row = ({ index, style }) => {
        const hsn = filteredHsnData[index]; // Access the specific HSN data based on index
        return (
            <MenuItem
                key={hsn.code} // Unique key for each MenuItem
                value={hsn.code}
                onClick={() => handleHsnChange({ target: { value: hsn.code } })} // Handle selection
                style={style}
            >
                {hsn.name} - {hsn.code}
            </MenuItem>
        );
    };

    return (
        <Stack p={'0 16px'} justifyContent={'center'} alignItems={'center'} flexDirection={'row'}>
            <Stack width={is1100 ? "100%" : "60rem"} rowGap={4} mt={is480 ? 4 : 6} mb={6} component={'form'} noValidate onSubmit={handleSubmit(handleAddProduct)}>
                {/* field area */}
                <Stack rowGap={3}>
                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>SKU ID</Typography>
                        <TextField {...register("SKUID", { required: 'SKUID is required' })} />
                        {errors.SKUID && (
                            <FormHelperText error>{errors.SKUID.message}</FormHelperText>
                        )}
                    </Stack>
                    <Stack spacing={2}>

                        <FormControl fullWidth>
                            <Typography variant='body1' gutterBottom>Select HSN Code</Typography>

                            {/* Search Input */}
                            <TextField
                                placeholder="Write HSN code or Category"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                fullWidth
                                margin="dense"
                            />

                            <Select
                                value={selectedHsn}
                                placeholder='Choose the HSN Code'
                                renderValue={(value) => {
                                    const selectedItem = hsnData.find(hsn => hsn.code === value);
                                    return selectedItem ? `${selectedItem.name} - ${selectedItem.code}` : '';
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 300, // Max height for the dropdown
                                            overflowY: 'auto', // Enable scrolling for the dropdown
                                        },
                                    },
                                }}
                            >
                                {/* Virtualized List for HSN Codes */}
                                <FixedSizeList

                                    height={300} // Height of the dropdown
                                    itemCount={filteredHsnData.length} // Total number of filtered items
                                    itemSize={35} // Height of each item
                                    width="100%"
                                >
                                    {({ index, style }) => (
                                        <Row index={index} style={style} /> // Render Row as a function
                                    )}
                                </FixedSizeList>
                            </Select>
                            {/* Display error if HSN is not selected */}
                            {hsnerrors.HSN && (
                                <FormHelperText error>{hsnerrors.HSN}</FormHelperText>
                            )}
                        </FormControl>

                        <TextField
                            placeholder="HSN"
                            variant="outlined"
                            value={selectedHsn}
                            onChange={(e) => setSelectedHsn(e.target.value)}
                        />
                    </Stack>
                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>Title</Typography>
                        <TextField {...register("title", { required: 'Title is required' })} />
                        {errors.title && (
                            <FormHelperText error>{errors.title.message}</FormHelperText>
                        )}
                    </Stack>

                    <Stack flexDirection={'row'}>
                        <FormControl fullWidth>
                            <InputLabel id="category-selection">Category</InputLabel>
                            <Select {...register("category", { required: "Category is required" })} onChange={(e) => {
                                const selectedCat = e.target.value;
                                const catname = categories.find((cat) => cat._id === selectedCat);

                                if (catname) {
                                    setCat(catname.name);
                                    // console.log(catname);
                                }
                            }} labelId="category-selection" label="Category">
                                {categories.map((category) => (
                                    <MenuItem key={category._id} value={category._id}>{category.name}</MenuItem>
                                ))}
                            </Select>
                            {errors.category && (
                                <FormHelperText error>{errors.category.message}</FormHelperText>
                            )}
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id="brand-selection">Brand</InputLabel>
                            <Select {...register("brand", { required: "Brand is required" })} labelId="brand-selection" label="Brand">
                                {brands.map((brand) => (
                                    <MenuItem key={brand._id} value={brand._id}>{brand.name}</MenuItem>
                                ))}
                            </Select>
                            {errors.brand && (
                                <FormHelperText error>{errors.brand.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Stack>

                    {cat === "clothing" &&
                        (
                            <ClothingComponent register={register} errors={errors} />
                        )}

                    {/* Other fields */}
                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>Description</Typography>
                        <TextField multiline rows={4} {...register("description", { required: 'Description is required' })} />
                        {errors.description && (
                            <FormHelperText error>{errors.description.message}</FormHelperText>
                        )}
                    </Stack>

                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>Price</Typography>
                        <TextField type='number' {...register("price", { required: 'Price is required' })} />
                        {errors.price && (
                            <FormHelperText error>{errors.price.message}</FormHelperText>
                        )}
                    </Stack>

                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>MRP : </Typography>
                        <TextField type='number' {...register("discountPercentage", { required: 'Discount Percentage is required' })} />
                        {errors.discountPercentage && (
                            <FormHelperText error>{errors.discountPercentage.message}</FormHelperText>
                        )}
                    </Stack>
                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>Shipping Charge</Typography>
                        <TextField type='number' {...register("ShippingCharge", { required: 'ShippingCharge is required' })} />
                        {errors.ShippingCharge && (
                            <FormHelperText error>{errors.ShippingCharge.message}</FormHelperText>
                        )}
                    </Stack>



                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>Stock Quantity</Typography>
                        <TextField type='number' {...register("stockQuantity", { required: 'Stock Quantity is required' })} />
                        {errors.stockQuantity && (
                            <FormHelperText error>{errors.stockQuantity.message}</FormHelperText>
                        )}
                    </Stack>
                    {cat == 'clothing' && <Stack fullWidth>
                        <Typography variant="h6">Available Sizes</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2 }}>
                            {availableSizes.map((size) => (
                                <FormControlLabel
                                    key={size._id}
                                    control={
                                        <Controller
                                            name="availableSizes"
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    value={size.name}
                                                    checked={field.value.includes(size.name)}
                                                    onChange={(e) => {
                                                        const newValue = e.target.checked
                                                            ? [...field.value, size.name]
                                                            : field.value.filter((v) => v !== size.name);
                                                        field.onChange(newValue);
                                                    }}
                                                />
                                            )}
                                        />
                                    }
                                    label={size.name}
                                />
                            ))}
                        </Box>
                        {errors.availableSizes && (
                            <FormHelperText error>{errors.availableSizes.message}</FormHelperText>
                        )}
                    </Stack>}

                    {/* Image Selection */}
                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>Thumbnail</Typography>
                        <TextField {...register("thumbnail", { required: "Thumbnail is required" })} />
                    </Stack>

                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>Product Images</Typography>

                        <Stack rowGap={2}>

                            <TextField {...register("image0", { required: "Image is required" })} />
                            <TextField {...register("image1", { required: "Image is required" })} />
                            <TextField {...register("image2", { required: "Image is required" })} />
                            <TextField {...register("image3", { required: "Image is required" })} />

                        </Stack>

                    </Stack>
                </Stack>
                <Typography variant='h3' fontWeight={400} gutterBottom>Packaging Deatil </Typography>

                <Stack>
                    <Typography variant='h6' fontWeight={400} gutterBottom>Weight(.gm)</Typography>
                    <TextField type='number' {...register("Weight", { required: 'Weight is required' })} />
                    {errors.Weight && (
                        <FormHelperText error>{errors.Weight.message}</FormHelperText>
                    )}
                </Stack>
                <Stack>
                    <Typography variant='h6' fontWeight={400} gutterBottom>length</Typography>
                    <TextField type='number' {...register("length", { required: 'length is required' })} />
                    {errors.length && (
                        <FormHelperText error>{errors.length.message}</FormHelperText>
                    )}
                </Stack>
                <Stack>
                    <Typography variant='h6' fontWeight={400} gutterBottom>breath</Typography>
                    <TextField type='number' {...register("breath", { required: 'breath is required' })} />
                    {errors.breath && (
                        <FormHelperText error>{errors.breath.message}</FormHelperText>
                    )}
                </Stack>
                <Stack>
                    <Typography variant='h6' fontWeight={400} gutterBottom>height</Typography>
                    <TextField type='number' {...register("height", { required: 'height is required' })} />
                    {errors.height && (
                        <FormHelperText error>{errors.height.message}</FormHelperText>
                    )}
                </Stack>

                {/* Submit button */}
                <Button type='submit' disabled={flag} variant='contained' size='large'>
                    {flag ? "Processing..." : "Add Product"}
                </Button>
            </Stack>
        </Stack>
    );
};
