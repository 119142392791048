import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import { Navbar } from '../features/navigation/components/Navbar';
import { Footer } from '../features/footer/Footer';

const PrivacyPolicy = () => {
    return (
        <>
            <Navbar />
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Box>
                        <Typography variant="h4" gutterBottom>
                            Privacy Policy
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Last updated: 08-10-2024
                        </Typography>

                        <Typography variant="h6" gutterBottom>  
                            1. Information We Collect
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We collect several types of information to better serve you:
                            - Personal Identification Information: Name, email, phone number, shipping address, billing address, etc.
                            - Transaction Information: Details about your purchases, payments, and order history.
                            - Technical Information: IP address, browser type, device information, and browsing actions on our website.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            2. How We Use Your Information
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We use the information collected in various ways, including to:
                            - Process your orders and payments.
                            - Provide customer support.
                            - Improve and personalize your experience on our website.
                            - Send marketing emails if you've subscribed to our newsletter.
                            - Analyze trends and website usage to improve our services.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            3. Data Sharing
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We do not sell your personal information. We only share your data with third parties for:
                            - Payment Processing: We may share your data with payment gateways like Razorpay.
                            - Shipping: Your information may be shared with Shiprocket for delivery.
                            - Legal Requirements: If required by law, we may disclose your information.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            4. Security
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We prioritize the security of your personal information. We use encryption, secure connections, and other measures to protect your data.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            5. Cookies
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We use cookies to enhance your browsing experience. Cookies help us remember your preferences and improve site functionality.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            6. Your Rights
                        </Typography>
                        <Typography variant="body1" paragraph>
                            You have the right to:
                            - Access the personal information we have about you.
                            - Request corrections to inaccurate or incomplete information.
                            - Request the deletion of your data (subject to legal requirements).
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            7. Changes to This Privacy Policy
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We may update this Privacy Policy from time to time. Any changes will be posted on this page, with the last updated date at the top.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            8. Contact Us
                        </Typography>
                        <Typography variant="body1" paragraph>
                            If you have any questions or concerns about our Privacy Policy, please contact us at:
                            - Email: support@dhrudeepenterprise.com
                            - Phone: 9510938002 , 72659018002
                            - Address: F-1 101, Patel chembar, near bapashitaram petrol pump, umragam, velanja, Surat, Gujarat, India, 394130
                        </Typography>
                    </Box>
                </Paper>
            </Container>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
