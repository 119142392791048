import React from "react";

const WhatsAppButton = () => {
  const phoneNumber = "+919510938002"; // Replace with your WhatsApp number in international format without '+'
  const message = "Hello! I'm interested in your Products."; // Optional predefined message

  return (
    <a
      href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        backgroundColor: "#25D366",
        color: "white",
        padding: "10px 20px",
        borderRadius: "50px",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      }}
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
        style={{ width: "24px", height: "24px", marginRight: "10px" }}
      />
      Chat with us
    </a>
  );
};

export default WhatsAppButton;
