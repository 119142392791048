import { FormHelperText, Paper, Stack, Typography, useMediaQuery, useTheme, Checkbox } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { useDispatch, useSelector } from 'react-redux';
import { selectWishlistItems } from '../../wishlist/WishlistSlice';
import { selectLoggedInUser } from '../../auth/AuthSlice';
import { addToCartAsync, selectCartItems } from '../../cart/CartSlice';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

export const ProductCard = ({ id, title, price, size, thumbnail, brand, stockQuantity, handleAddRemoveFromWishlist, isWishlistCard, isAdminCard, discountPercentage, category }) => {
    const [selectedSize, setSelectedSize] = useState(null)
    const navigate = useNavigate();
    const wishlistItems = useSelector(selectWishlistItems);
    const loggedInUser = useSelector(selectLoggedInUser);
    const cartItems = useSelector(selectCartItems);
    const dispatch = useDispatch();
    let isProductAlreadyinWishlist = -1;

    const theme = useTheme();
    const is1410 = useMediaQuery(theme.breakpoints.down(1410));
    const is932 = useMediaQuery(theme.breakpoints.down(932));
    const is752 = useMediaQuery(theme.breakpoints.down(752));
    const is500 = useMediaQuery(theme.breakpoints.down(500));
    const is608 = useMediaQuery(theme.breakpoints.down(608));
    const is488 = useMediaQuery(theme.breakpoints.down(488));
    const is408 = useMediaQuery(theme.breakpoints.down(408));

    isProductAlreadyinWishlist = wishlistItems.some((item) => item.product._id === id);
    const isProductAlreadyInCart = cartItems.some((item) => item.product._id === id);

    const [flag, setFlag] = useState(false)

    const handleAddToCart = async (e, cat) => {
        e.stopPropagation();
        setFlag(true); // Set flag true before processing

        if (!loggedInUser) {
            navigate('/login');
        } else {
            try {
                if (cat === "clothing") {
                    if (selectedSize) {
                        const data = { user: loggedInUser._id, product: id, size: selectedSize };
                        await dispatch(addToCartAsync(data));
                    } else {
                        toast.error("Select the size");
                    }
                } else {
                    const data = { user: loggedInUser._id, product: id, category: cat };
                    await dispatch(addToCartAsync(data));
                }
            } catch (error) {
                console.error("Error adding to cart:", error);
            } finally {
                setFlag(false); // Ensure flag is reset to false
            }
        }
    };


    const handleSizeSelect = (size) => {
        setSelectedSize(size)
    }

    return (
        <>
            {isProductAlreadyinWishlist !== -1 ? (
                <Stack
                    whileHover={{ scale: 1.03, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}
                    whileTap={{ scale: 1 }}
                    component={isAdminCard ? "" : isWishlistCard ? "" : is408 ? '' : Paper}
                    mt={is408 ? 2 : 0}
                    elevation={2}
                    p={2}
                    width={is408 ? 'auto' : is488 ? "210px" : is608 ? "250px" : is752 ? "310px" : is932 ? '250px' : is1410 ? '320px' : '350px'}
                    sx={{
                        cursor: "pointer",
                        position: "relative",
                        borderRadius: "10px",
                        transition: "transform 0.3s, box-shadow 0.3s",
                        "&:hover": { transform: "scale(1.03)", boxShadow: "0 8px 24px rgba(0, 0, 0, 0.1)" }
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (!loggedInUser) {
                            navigate('/login');
                        } else { navigate(`/product-details/${id}`) }
                    }}
                >
                    {/* Discount Label */}
                    {discountPercentage && (
                        <Stack
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                backgroundColor: '#ff4d4d',
                                color: 'white',
                                padding: '4px 12px',
                                borderRadius: '50px',
                                fontSize: '0.9rem',
                                fontWeight: 'bold',
                                zIndex: 10,
                            }}
                        >
                            {Math.round(((discountPercentage - price) / discountPercentage) * 100)}% OFF
                        </Stack>
                    )}

                    {/* Image display */}
                    <Stack sx={{ overflow: 'hidden', borderRadius: '10px' }}>
                        <img
                            width={'100%'}
                            style={{ aspectRatio: 1 / 1, objectFit: "cover", borderRadius: '10px' }}
                            height={'100%'}
                            src={thumbnail}
                            alt={`${title} photo unavailable`}
                        />
                    </Stack>

                    {/* Lower section */}
                    <Stack flex={2} justifyContent={'flex-end'} spacing={1} rowGap={2} mt={2}>
                        <Stack>
                            <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant='h6' fontWeight={500} color="text.primary">{title}</Typography>
                                {!isAdminCard && (
                                    <motion.div
                                        whileHover={{ scale: 1.3, y: -10, zIndex: 100 }}
                                        whileTap={{ scale: 1 }}
                                        transition={{ duration: .4, type: "spring" }}
                                    >
                                        <Checkbox
                                            onClick={(e) => e.stopPropagation()}
                                            checked={isProductAlreadyinWishlist}
                                            onChange={(e) => handleAddRemoveFromWishlist(e, id)}
                                            icon={<FavoriteBorder />}
                                            checkedIcon={<Favorite sx={{ color: 'red' }} />}
                                        />
                                    </motion.div>
                                )}
                            </Stack>
                            <Typography color={"text.secondary"}>{brand}</Typography>
                        </Stack>

                        {size && size.length > 0 && (
                            <Stack flexDirection="row" flexWrap={'wrap'} alignItems="center" display="block">
                                <Typography fontWeight={500} color="text.secondary">Size: </Typography>
                                <Stack flexDirection="row" flexWrap={'wrap'} columnGap={"0.5rem"} rowGap="0.5rem" mt={1}>
                                    {size.map((sz) => (
                                        <motion.div
                                            key={sz}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleSizeSelect(sz);
                                            }}
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 1 }}
                                            style={{
                                                border: selectedSize === sz ? 'none' : '1px solid gray',
                                                borderRadius: "8px",
                                                width: "40px",
                                                height: "30px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                backgroundColor: selectedSize === sz ? "#ff4d4d" : "#f5f5f5",
                                                color: selectedSize === sz ? "white" : "inherit",
                                                transition: 'background-color 0.3s, color 0.3s',
                                            }}
                                        >
                                            <p>{sz}</p>
                                        </motion.div>
                                    ))}
                                </Stack>
                            </Stack>
                        )}

                        {/* Price and Add to Cart */}
                        <Stack sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                            <Stack direction="row" spacing={1}>
                                <Typography color="primary" fontWeight={"bold"} fontSize="1.2rem" >₹ {price}</Typography>
                                <Typography style={{ textDecoration: 'line-through', color: '#999' }}>
                                    ₹ {parseInt(discountPercentage)}
                                </Typography>
                            </Stack>
                            {!isWishlistCard && !isProductAlreadyInCart && !isAdminCard && (
                                <motion.button
                                    whileHover={{ scale: 1.030 }}
                                    whileTap={{ scale: 1 }}
                                    onClick={(e) => handleAddToCart(e, category?.name)}
                                    disabled={flag}
                                    style={{
                                        padding: "10px 20px",
                                        borderRadius: "5px",
                                        outline: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        background: "linear-gradient(to right, #ff4d4d, #ff9966)",
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: "1rem",
                                        transition: 'all 0.2s ease-in-out',

                                    }}
                                >
                                    {flag ? "Adding to Cart" : "Add to Cart"}
                                </motion.button>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            ) : ""}
        </>
    );
};
