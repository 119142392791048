import {
    Stack,
    TextField,
    Typography,
    Button,
    Menu,
    MenuItem,
    Select,
    Grid,
    FormControl,
    Radio,
    Paper,
    IconButton,
    Box,
    useTheme,
    useMediaQuery
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { Cart } from "../../cart/components/Cart";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    addAddressAsync,
    selectAddressStatus,
    selectAddresses
} from "../../address/AddressSlice";
import { selectLoggedInUser } from "../../auth/AuthSlice";
import { Link, useNavigate } from "react-router-dom";
import {
    createOrderAsync,
    selectCurrentOrder,
    selectOrderStatus
} from "../../order/OrderSlice";
import { resetCartByUserIdAsync, selectCartItems } from "../../cart/CartSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SHIPPING, TAXES } from "../../../constants";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { axiosi } from "../../../config/axios";



export const Checkout = () => {
    const status = "";
    const addresses = useSelector(selectAddresses);
    const [selectedAddress, setSelectedAddress] = useState(addresses[0]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("selectmethod");
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const loggedInUser = useSelector(selectLoggedInUser);
    const addressStatus = useSelector(selectAddressStatus);
    const navigate = useNavigate();
    const cartItems = useSelector(selectCartItems);
    const orderStatus = useSelector(selectOrderStatus);
    const currentOrder = useSelector(selectCurrentOrder);
    const orderTotal = cartItems.reduce((acc, item) => (item.product.price * item.quantity) + acc, 0);
    const [flag, setFlag] = useState(false)
    const theme = useTheme();
    const is900 = useMediaQuery(theme.breakpoints.down(900));
    const is480 = useMediaQuery(theme.breakpoints.down(480));

    useEffect(() => {
        if (addressStatus === "fulfilled") {
            reset();
        } else if (addressStatus === "rejected") {
            alert("Error adding your address");
        }
    }, [addressStatus]);


    useEffect(() => {
        console.log(currentOrder);
        setFlag(false)
        if (currentOrder && currentOrder?._id) {
            dispatch(resetCartByUserIdAsync(loggedInUser?._id));
            navigate(`/order-success/${currentOrder?._id}`);
        }
    }, [currentOrder]);

    const handleAddAddress = (data) => {
        const address = { ...data, user: loggedInUser._id };
        dispatch(addAddressAsync(address));
    };

    const [couponCode, setCouponCode] = useState('');
    const [discountAmount, setDiscountAmount] = useState(0);

    const [couponAmount, setCouponAmount] = useState(null)




    const handleApplyCoupon = async () => {
        if (!couponCode) {
            toast.error("Please enter a coupon code");
            return;
        }

        try {
            const { data } = await axiosi.post("/api/coupons/validate", { code: couponCode });

            if (data.isValid) {
                setDiscountAmount(data.discount); // Set discount percentage

                const shippingCharge = cartItems.every(item => item.product.ShippingCharge === 0)
                    ? 0
                    : cartItems.reduce((acc, item) => acc + item.product.ShippingCharge, 0);

                // Calculate the order total after discount
                let orderTotalWithDiscount = orderTotal - (orderTotal * (data.discount / 100)); // Use the received discount value directly

                // Add shipping charge to get final amount
                let finalAmount = orderTotalWithDiscount + shippingCharge;

                setCouponAmount(finalAmount); // Set the final amount with discount and shipping

                toast.success("Coupon applied successfully");
            } else {
                toast.error("Invalid or expired coupon");
            }
        } catch (error) {
            toast.error("Error validating coupon");
        }
    };



    useEffect(() => {
        if (discountAmount) {
            const shippingCharge = cartItems.every(item => item.product.ShippingCharge === 0)
                ? 0
                : cartItems.reduce((acc, item) => acc + item.product.ShippingCharge, 0);

            // Calculate the order total after discount
            let orderTotalWithDiscount = orderTotal - (orderTotal * (discountAmount / 100)); // Use the received discount value directly

            // Add shipping charge to get final amount
            let finalAmount = orderTotalWithDiscount + shippingCharge;

            setCouponAmount(finalAmount);
        }


    }, [cartItems])

    const handleCreateOrder = async () => {

        if (addresses.length == 0) {
            toast.error("add address")
            return;
        }
        if (selectedAddress == undefined) {
            toast.error("select Address")
            return;
        }
        if (selectedPaymentMethod === "selectmethod") {
            toast.error("Select Payment Mode");
            return;
        }
        setFlag(true)
        const ShippingCharge = cartItems.every(item => item.product.ShippingCharge === 0)
            ? cartItems.reduce((acc, item) => acc + item.product.ShippingCharge, 0)
            : cartItems.length > 0
                ? cartItems[0].product.ShippingCharge
                : 0;

        let orderTotalWithDiscount = orderTotal;
        if (discountAmount > 0) {
            orderTotalWithDiscount = orderTotal - (orderTotal * (discountAmount / 100));
        }
        const order = {
            user: loggedInUser._id,
            item: cartItems,
            address: selectedAddress,
            paymentMode: selectedPaymentMethod,
            total: orderTotalWithDiscount + ShippingCharge,
        };
        if (selectedPaymentMethod === "COD") {

            await dispatch(createOrderAsync(order));
            return;
        }

        // Dispatch the order creation action

        if (selectedPaymentMethod === "CARD") {
            try {
                const { data } = await axiosi.post("/api/payment/razorpay", {
                    amount: orderTotalWithDiscount + ShippingCharge,
                    currency: "INR",
                });

                const options = {
                    key: process.env.REACT_APP_RAZORPAY_KEY_ID,
                    amount: data.order.amount,
                    currency: "INR",
                    name: "Mandeep EnterPrise",
                    description: "Test Transaction",
                    order_id: data.order.id,  // Use Razorpay order id
                    handler: async function (response) {
                        // Handle success
                        // console.log(response);
                        toast("Payment successful!");
                        order.razorpay_payment_id = response.razorpay_payment_id
                        order.razorpay_order_id = response.razorpay_order_id
                        order.razorpay_signature = response.razorpay_signature
                        dispatch(createOrderAsync(order));
                    },
                    prefill: {
                        name: loggedInUser.name,
                        email: loggedInUser.email,
                        contact: loggedInUser.mobile,
                    },
                    theme: {
                        color: "#3399cc",
                    },
                };

                const rzp1 = new window.Razorpay(options);
                rzp1.open();
            } catch (error) {
                console.error("Error creating Razorpay order", error);
            } finally {
                setFlag(false)
            }
        }
    };




    return (
        <Stack flexDirection={"row"} p={2} rowGap={10} justifyContent={"center"} flexWrap={"wrap"} mb={"5rem"} mt={2} columnGap={4} alignItems={"flex-start"}>
            {/* Left Box */}
            <Stack rowGap={4}>
                {/* Heading */}
                <Stack flexDirection={"row"} columnGap={is480 ? 0.3 : 1} alignItems={"center"}>
                    <motion.div whileHover={{ x: -5 }}>
                        <IconButton component={Link} to={"/cart"}>
                            <ArrowBackIcon fontSize={is480 ? "medium" : "large"} />
                        </IconButton>
                    </motion.div>
                    <Typography variant="h4">Shipping Information</Typography>
                </Stack>

                {/* Address Form */}
                <Stack component={"form"} noValidate rowGap={2} onSubmit={handleSubmit(handleAddAddress)}>
                    <Stack>
                        <Typography gutterBottom>Type</Typography>
                        <TextField placeholder="Eg. Home, Business" {...register("type", { required: true })} />
                    </Stack>
                    <Stack>
                        <Typography gutterBottom>Name</Typography>
                        <TextField {...register("name", { required: true })} />
                    </Stack>
                    <Stack>
                        <Typography gutterBottom>Street</Typography>
                        <TextField {...register("street", { required: true })} />
                    </Stack>
                    <Stack>
                        <Typography gutterBottom>Country</Typography>
                        <TextField {...register("country", { required: true })} />
                    </Stack>
                    <Stack>
                        <Typography gutterBottom>Phone Number</Typography>
                        <TextField type="number" {...register("phoneNumber", { required: true })} />
                    </Stack>
                    <Stack flexDirection={"row"}>
                        <Stack width={"100%"}>
                            <Typography gutterBottom>City</Typography>
                            <TextField {...register("city", { required: true })} />
                        </Stack>
                        <Stack width={"100%"}>
                            <Typography gutterBottom>State</Typography>
                            <TextField {...register("state", { required: true })} />
                        </Stack>
                        <Stack width={"100%"}>
                            <Typography gutterBottom>Postal Code</Typography>
                            <TextField type="number" {...register("postalCode", { required: true })} />
                        </Stack>
                    </Stack>
                    <Stack flexDirection={"row"} alignSelf={"flex-end"} columnGap={1}>
                        <LoadingButton loading={status === "pending"} type="submit" variant="contained">
                            Add
                        </LoadingButton>
                        <Button color="error" variant="outlined" onClick={() => reset()}>
                            Reset
                        </Button>
                    </Stack>
                </Stack>

                {/* Existing Address */}
                <Stack rowGap={3}>
                    <Stack>
                        <Typography variant="h6">Address</Typography>
                        <Typography variant="body2" color={"text.secondary"}>
                            Choose from existing Addresses
                        </Typography>
                    </Stack>

                    <Grid container gap={2} width={is900 ? "auto" : "50rem"} justifyContent={"flex-start"} alignContent={"flex-start"}>
                        {addresses.map((address, index) => (
                            <FormControl key={address._id} item>
                                <Stack p={2} width={is480 ? "100%" : "20rem"} height={is480 ? "auto" : "15rem"} rowGap={2} component={Paper} elevation={1}>
                                    <Stack flexDirection={"row"} alignItems={"center"}>
                                        <Radio checked={selectedAddress === address} name="addressRadioGroup" value={selectedAddress} onChange={() => setSelectedAddress(addresses[index])} />
                                        <Typography>{address.type}</Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography>{address.name}</Typography>
                                        <Typography>{address.street}</Typography>
                                        <Typography>{address.state}, {address.city}, {address.country}, {address.postalCode}</Typography>
                                        <Typography>{address.phoneNumber}</Typography>
                                    </Stack>
                                </Stack>
                            </FormControl>
                        ))}
                    </Grid>
                </Stack>

                {/* Payment Methods */}
                <Stack rowGap={3}>
                    <Stack>
                        <Typography variant="h6">Payment Methods</Typography>
                        <Typography variant="body2" color={"text.secondary"}>
                            Please select a payment method
                        </Typography>
                    </Stack>

                    <Stack rowGap={2}>
                        <Stack flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                            <Radio value={selectedPaymentMethod} name="paymentMethod" checked={selectedPaymentMethod === "COD"} onChange={() => setSelectedPaymentMethod("COD")} />
                            <Typography>Cash on Delivery</Typography>
                        </Stack>

                        <Stack flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                            <Radio value={selectedPaymentMethod} name="paymentMethod" checked={selectedPaymentMethod === "CARD"} onChange={() => setSelectedPaymentMethod("CARD")} />
                            <Typography>Credit / Debit / UPI</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>

            <Stack spacing={2}>
                <Typography variant="h6">Apply Coupon</Typography>
                <Stack direction="row" spacing={2}>
                    <TextField
                        label="Coupon Code"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        variant="outlined"
                    />
                    <Button variant="contained" onClick={handleApplyCoupon}>Apply Coupon</Button>
                </Stack>
                {discountAmount > 0 && (
                    <Typography color="success.main">Discount Applied: {discountAmount}%</Typography>
                )}
            </Stack>

            {/* Right Cart Summary */}
            <Stack height={is480 ? "auto" : "auto"} direction="row" >


                <Cart checkout={true} hideButton handleCreateOrder={handleCreateOrder} flag={flag} couponAmount={couponAmount} />

                {/* <Stack alignItems={"flex-end"} mt={3} mb={2}>
                    <Button onClick={handleCreateOrder} variant="contained" sx={{ paddingBlock: "0.5rem", paddingInline: "3rem", fontSize: "1rem" }}>
                        PLACE ORDER
                    </Button>
                </Stack> */}
            </Stack>
        </Stack>
    );
};
