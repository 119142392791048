import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllOrdersAsync, resetOrderUpdateStatus, selectOrderUpdateStatus, selectOrders, updateOrderByIdAsync } from '../../order/OrderSlice'
import { Avatar, Button, Chip, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify';
import { noOrdersAnimation } from '../../../assets/index'
import Lottie from 'lottie-react'

export const AdminOrders = () => {
  const dispatch = useDispatch()
  const orders = useSelector(selectOrders)
  const [editIndex, setEditIndex] = useState(-1)
  const [searchQuery, setSearchQuery] = useState('') // For search
  const orderUpdateStatus = useSelector(selectOrderUpdateStatus)
  const theme = useTheme()
  const is1620 = useMediaQuery(theme.breakpoints.down(1620))
  const is1200 = useMediaQuery(theme.breakpoints.down(1200))
  const is820 = useMediaQuery(theme.breakpoints.down(820))
  const is480 = useMediaQuery(theme.breakpoints.down(480))
  const { register, handleSubmit, formState: { errors }, } = useForm()

  useEffect(() => {
    dispatch(getAllOrdersAsync())
  }, [dispatch])

  useEffect(() => {
    if (orderUpdateStatus === 'fulfilled') {
      toast.success("Status updated")
    } else if (orderUpdateStatus === 'rejected') {
      toast.error("Error updating order status")
    }
  }, [orderUpdateStatus])

  useEffect(() => {
    return () => {
      dispatch(resetOrderUpdateStatus())
    }
  }, [])

  const handleUpdateOrder = (data) => {
    const update = { ...data, _id: orders[editIndex]._id }
    setEditIndex(-1)
    dispatch(updateOrderByIdAsync(update))
  }

  const editOptions = ['Pending', 'Dispatched', 'Out for delivery', 'Delivered', 'Cancelled']

  const getStatusColor = (status) => {
    const statusColors = {
      'Pending': { bgcolor: '#dfc9f7', color: '#7c59a4' },
      'Dispatched': { bgcolor: '#feed80', color: '#927b1e' },
      'Out for delivery': { bgcolor: '#AACCFF', color: '#4793AA' },
      'Delivered': { bgcolor: '#b3f5ca', color: '#548c6a' },
      'Cancelled': { bgcolor: '#fac0c0', color: '#cc6d72' }
    };
    return statusColors[status] || {};
  }

  // Filter orders based on the search query
  const filteredOrders = orders.filter(order => {
    return (
      order._id.includes(searchQuery) ||
      order.item.some(product =>
        product.product.SKUID.includes(searchQuery) ||
        product.product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.size.toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(product.quantity).includes(searchQuery)
      ) ||
      order.user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.address.some(addr =>
        addr.street.toLowerCase().includes(searchQuery.toLowerCase()) ||
        addr.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
        addr.state.toLowerCase().includes(searchQuery.toLowerCase()) ||
        addr.postalCode.includes(searchQuery)
      ) ||
      order.paymentMode.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.razorpay_payment_id?.includes(searchQuery) ||
      order.razorpay_order_id?.includes(searchQuery) ||
      order.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
      new Date(order.createdAt).toDateString().includes(searchQuery.toLocaleLowerCase())
    )
  })

  return (
    <Stack justifyContent={'center'} alignItems={'center'}>
      {/* Search Input */}
      <TextField
        label="Search Orders"
        variant="outlined"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ marginBottom: 2, marginTop: 2 }}
      />

      <Stack mt={5} mb={3} component={'form'} noValidate onSubmit={handleSubmit(handleUpdateOrder)}>
        {
          filteredOrders.length ?
            <TableContainer sx={{ width: is1620 ? "95vw" : "auto", overflowX: 'auto' }} component={Paper} elevation={2}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order</TableCell>
                    <TableCell align="left">Id</TableCell>
                    <TableCell align="left">Item</TableCell>
                    <TableCell align="right">Total Amount</TableCell>
                    <TableCell align="right">Customer Name</TableCell>
                    <TableCell align="right">Customer Email</TableCell>
                    <TableCell align="right">Shipping Address</TableCell>
                    <TableCell align="right">Payment Method</TableCell>
                    <TableCell align="right">Order Date</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredOrders.map((order, index) => (
                    <TableRow key={order._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">{index}</TableCell>
                      <TableCell align="right">{order._id}
                        {order.item.map((product) => (
                          <Typography key={product.product.SKUID}>SKUID: {product.product.SKUID}</Typography>
                        ))}
                      </TableCell>
                      <TableCell align="right">
                        {order.item.map((product) => (
                          <Stack key={product.product.SKUID} mt={2} flexDirection={'row'} alignItems={'center'} columnGap={2}>
                            <Avatar src={product.product.thumbnail}></Avatar>
                            <Typography>{product.product.title}</Typography>
                            <Typography>Size: {product.size}</Typography>
                            <Typography>Qty: {product.quantity}</Typography>
                          </Stack>
                        ))}
                      </TableCell>
                      <TableCell align="right">{order.total}</TableCell>
                      <TableCell align="right">{order.user.name}</TableCell>
                      <TableCell align="right">{order.user.email}</TableCell>
                      <TableCell align="right">
                        <Stack>
                          <Typography>{order.address[0]?.street}</Typography>
                          <Typography>{order.address[0].city}</Typography>
                          <Typography>{order.address[0].state}</Typography>
                          <Typography>{order.address[0].postalCode}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="right">{order.paymentMode}, razorpay_payment_id: {order?.razorpay_payment_id}, razorpay_order_id: {order?.razorpay_order_id}</TableCell>
                      <TableCell align="right">{new Date(order.createdAt).toDateString()}</TableCell>
                      <TableCell align="right">
                        {editIndex === index ? (
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Update status</InputLabel>
                            <Select
                              defaultValue={order.status}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Update status"
                              {...register('status', { required: 'Status is required' })}
                            >
                              {editOptions.map((option) => (
                                <MenuItem key={option} value={option}>{option}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <Chip label={order.status} sx={getStatusColor(order.status)} />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {editIndex === index ? (
                          <Button>
                            <IconButton type='submit'><CheckCircleOutlinedIcon /></IconButton>
                          </Button>
                        ) : (
                          <IconButton onClick={() => setEditIndex(index)}><EditOutlinedIcon /></IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            : (
              <Stack sx={{ height: '500px', width: is1200 ? "95vw" : "60vw" }}>
                <Lottie animationData={noOrdersAnimation} />
              </Stack>
            )
        }
      </Stack>
    </Stack>
  )
}
