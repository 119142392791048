import axios from "axios";

// export const axiosi=axios.create({withCredentials:true,baseURL:process.env.REACT_APP_BASE_URL})
export const axiosi = axios.create({
  withCredentials: true,
  baseURL: "https://mern-ecom-backend-rb0a.onrender.com",
});
export const baseurl = "https://mern-ecom-backend-rb0a.onrender.com";
// https://mern-ecom-backend-rb0a.onrender.com

// http://localhost:8000
